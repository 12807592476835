import React, {useEffect, useState} from "react";
import {ButtonIcon, getAccountFio, Loading, Search, Select,} from "@vokymlak/kabinet-ui";
import ModalKanbanTaskAdd from "./ModalKanbanTaskAdd";
import ModalKanbanTaskEdit from "./ModalKanbanTaskEdit";
import {ScrollBlock} from "../../../../components/ScrollBlock/ScrollBlock";
import ModalKanbanTaskShow from "./ModalKanbanTaskShow";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import axios from "axios";
import {ROOT_URL} from "../../../../config/url";

export const StrictModeDroppable = (props) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable droppableId={props.droppableId}>{props.children}</Droppable>;
};

function KanbanSpacePage(props) {
  const {
    profile,

    setObject,
    object,
    isUpdateObject,
    setIsUpdateObject,

    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    // isArchive,
    // setIsArchive,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    changeViewer,

    isCount,
    isArchive,
    isTopicNull,
    kanbanSpaceUuid,
    authorId,
    executorId,

    types,
    statuses,
    employees,
    topics,

    setStatus,
    status,

    selectAuthor,
    changeSelectAuthor,
    selectEmployee,
    changeSelectEmployee,
  } = props;

  const [isModalObjectAdd, setIsModalObjectAdd] = useState(false);
  const [modalItemAdminEdit, setModalItemAdminEdit] = useState(null);
  const [modalItemShow, setModalItemShow] = useState(null);

  const getSelectExecutorOptions = (list) => {
    const options = [
      {
        value: "0",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.account.id,
        title: getAccountFio(item.account),
      };

      options.push(option);
    });

    options.push({
      value: 1,
      title: "Администратор",
    });

    return options;
  };

  const onDragEndGroup = (result) => {
    // console.log(result)
    if (result.destination) {
      const oldColumn = result.source.droppableId
      const newColumn = result.destination.droppableId
      const oldIndex = result.source.index
      const newIndex = result.destination.index
      const cardId = result.draggableId

      console.log("oldColumn", oldColumn)
      console.log("newColumn", newColumn)
      console.log("oldIndex", oldIndex)
      console.log("newIndex", newIndex)
      console.log("cardId", cardId)

      const columns = (!!object.kanban_states && ([...object.kanban_states])) || []

      let item = null

      columns.map((column) => {
        if (oldColumn === column.uuid) {
          column.kanban_tasks.map((task) => {
            if (task.uuid === cardId) {
              item = { ...task }
            }
          })
          column.kanban_tasks.splice(oldIndex, 1)
        }
      })

      columns.map((column) => {
        if (newColumn === column.uuid) {
          if (!column.kanban_tasks || (!!column.kanban_tasks && column.kanban_tasks.length === 0)) {
            column.kanban_tasks = [item]
          } else {
            column.kanban_tasks.push(item)
            column.kanban_tasks = dndArray(
              column.kanban_tasks,
              column.kanban_tasks.length - 1,
              newIndex,
            )
          }
        }
      })

      setObject({...object, kanban_states: columns});

      axios
        .get(
          '/api/kanban-state/' + newColumn + '/kanban-task/' + cardId + '/index/' + newIndex,
          {
            withCredentials: false,
            baseURL: ROOT_URL,
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + localStorage.getItem('tokenSession'),
            },
          },
        )
        .then((response) => {
          // if (!response.data.response.message) {
          setIsUpdateObject(!isUpdateObject);
          // appBookmarksInit(appBookmarksDispatch).then();
          // }
        })
        .catch((error) => {
          setIsUpdateObject(!isUpdateObject);
          // appBookmarksInit(appBookmarksDispatch).then();
        })
    }
  }

  const dndArray = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1
      while (k--) {
        arr.push(undefined)
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
    return arr
  }

  return (
    // <ScrollBlock style={{width: "100%", height: "100%"}}>
    <div className="Page" style={{
      padding: "12px 20px 0 20px"
    }}>
      <div className="Page__title">
        <div className="Title__text">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              fontSize: 19,
              fontWeight: 700,
            }}
          >
            <div>
              {!!object && object.title}

              {/*{!!list && (*/}
              {/*  <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>*/}
              {/*    ({count})*/}
              {/*  </span>*/}
              {/*)}*/}
            </div>
          </div>
          {/*<ButtonIcon*/}
          {/*  tooltipText={"Архив"}*/}
          {/*  iconName={"custom-archive"}*/}
          {/*  iconColor={isArchive ? "#202020" : "#20202075"}*/}
          {/*  size={24}*/}
          {/*  style={{*/}
          {/*    marginLeft: 16,*/}
          {/*  }}*/}
          {/*  onClick={() => setIsArchive(!isArchive)}*/}
          {/*/>*/}
          <span style={{width: 140, marginLeft: 16}}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>
          <span style={{width: 256, marginLeft: 16, fontSize: 14, fontWeight: 400}}>
              <Select
                isFixed={true}
                isDefaultOption={false}
                value={selectAuthor}
                onChange={(event) => (changeSelectAuthor(Number(event.target.value)))}
                options={
                  !!employees
                    ? getSelectExecutorOptions(employees)
                    : getSelectExecutorOptions([])
                }
                label="Автор"
              />
            </span>
          <span style={{width: 256, marginLeft: 16, fontSize: 14, fontWeight: 400}}>
              <Select
                isFixed={true}
                isDefaultOption={false}
                value={selectEmployee}
                onChange={(event) => (changeSelectEmployee(Number(event.target.value)))}
                options={
                  !!employees
                    ? getSelectExecutorOptions(employees)
                    : getSelectExecutorOptions([])
                }
                label="Исполнитель"
              />
            </span>
        </div>

        <div className="Title__settings">
          <ButtonIcon
            tooltipText={"Обновить"}
            iconName={"custom-change-circle"}
            iconColor={"#20202075"}
            size={28}
            style={{marginLeft: 8}}
            onClick={() => (setIsUpdateObject(!isUpdateObject))}
          />

          <ButtonIcon
            tooltipText={"Добавить"}
            iconName={"custom-add"}
            iconColor={"#0064ff"}
            size={36}
            style={{marginLeft: 12}}
            onClick={() => setIsModalObjectAdd(true)}
          />
        </div>
      </div>

      {!!object && (
        <hr className={"Page__hr"}/>
      )}

      {!object && (
        <div className={"Page__loader"}>
          <Loading/>
        </div>
      )}

      {!!object && (
        <ScrollBlock style={{width: "100%", height: "100%"}}>
          <div className=""
               style={{
                 display: "flex",
                 flexWrap: "nowrap",
                 boxSizing: "border-box",
                 height: "100%",
                 width: "100%"
               }}>

            <DragDropContext onDragEnd={onDragEndGroup}>
              {!!object.kanban_states && object.kanban_states.map(state => (
                <div key={'state_' + state.uuid}
                     style={{
                       width: 260,
                       flex: "0 0 auto",
                       backgroundColor: "#dbdbdb",
                       border: "1px solid #bdbdbd",
                       height: "calc(100% - 24px)",
                       borderRadius: 11,
                       margin: "0 24px 0 0",
                     }}>
                  <div
                    style={{
                      height: "calc(100% - 6px)",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}>
                    <div
                      style={{
                        alignItems: "center",
                        boxSizing: "border-box",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        fontSize: "16px",
                        fontWeight: "700",
                        // height: "42px",
                        justifyContent: "space-between",
                        padding: "14px 16px 9px",
                        width: "100%",
                      }}>
                      {state.title}
                    </div>

                    <div
                      style={{
                        boxSizing: "border-box",
                        height: "calc(100% - 42px)",
                        padding: "0 12px",
                        width: "100%"
                      }}>
                      <ScrollBlock style={{width: "100%", height: "100%"}}>

                        <StrictModeDroppable droppableId={String(state.uuid)}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}

                              style={((!state.kanban_tasks || (!!state.kanban_tasks && state.kanban_tasks.length === 0)) && {
                                borderRadius: 7,
                                backgroundColor: "#20202005",
                                minHeight: "100%"
                              }) || {}}
                            >

                              {!!state.kanban_tasks && state.kanban_tasks.map((task, index) => (
                                <Draggable
                                  key={'task_' + task.uuid}
                                  draggableId={String(task.uuid)}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.dragHandleProps}
                                      {...provided.draggableProps}
                                    >

                                      <div
                                           style={{
                                             backgroundColor: "white",
                                             marginBottom: 6,
                                             padding: 8,
                                             borderRadius: 7,
                                             cursor: "pointer",
                                             wordWrap: "break-word"
                                           }}
                                           onClick={() => setModalItemShow(task)}
                                      >
                                        {task.title}
                                        {!!task.description && (
                                          <div style={{
                                            fontSize: 14,
                                            opacity: .75,
                                            wordWrap: "break-word"
                                          }}>{task.description}</div>
                                        )}
                                      </div>

                                    </div>
                                  )}
                                </Draggable>
                              ))}

                              {provided.placeholder}
                            </div>
                          )}
                        </StrictModeDroppable>

                      </ScrollBlock>
                    </div>

                  </div>
                </div>
              ))}
            </DragDropContext>
          </div>
        </ScrollBlock>
      )}


      {isModalObjectAdd && (
        <ModalKanbanTaskAdd
          object={object}
          successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
          handleClose={() => setIsModalObjectAdd(false)}
        />
      )}

      {!!modalItemAdminEdit && (
        <ModalKanbanTaskEdit
          object={object}
          item={modalItemAdminEdit}
          successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
          handleClose={() => setModalItemAdminEdit(null)}
        />
      )}

      {!!modalItemShow && (
        <ModalKanbanTaskShow
          object={object}
          item={modalItemShow}
          successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
          handleClose={() => setModalItemShow(null)}
        />
      )}
    </div>
    // </ScrollBlock>
  );
}

export default KanbanSpacePage;
