import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {
  OPPORTUNITY_DASHBOARD,
  OPPORTUNITY_EMPLOYEES,
  OPPORTUNITY_HANDBOOK,
  OPPORTUNITY_KANBAN,
  OPPORTUNITY_LEAD,
  OPPORTUNITY_MY,
  OPPORTUNITY_NOMENCLATURES,
  OPPORTUNITY_NOMENCLATURES_ALL,
  OPPORTUNITY_ORDER,
  OPPORTUNITY_ORDER_ALL,
  OPPORTUNITY_ORDER_STATUS,
  OPPORTUNITY_PLAN,
  OPPORTUNITY_PLAN_DISPATCH,
  OPPORTUNITY_PRICES, OPPORTUNITY_PRODUCT_WAREHOUSE,
  OPPORTUNITY_SITE,
  OPPORTUNITY_SUMMARY,
  OPPORTUNITY_TICKETS,
  OPPORTUNITY_TIME_SHEET,
  OPPORTUNITY_TIME_TRACKING,
  ROLE_ADMINISTRATOR,
  ROLE_DIRECTOR,
} from "../config/constants";
import {isProfileOpportunityTypeTags, isProfileRoleTypeTags, useProfile} from "@vokymlak/kabinet-ui";
import {BuyerOrders} from "./ScreenOrders/BuyerOrders";
import OrderMarks from "./ScreenKpi/OrderMarks/OrderMarks";
import {StateOrders} from "./ScreenOrders/StateOrders";
import {PlanKneading} from "./ScreenPlans/PlanKneading";
import PlanProviding from "./ScreenPlans/PlanProviding/PlanProviding";
import {ViewOrders} from "./ScreenOrders/ViewOrders";
import {Order} from "./ScreenOrders/Order/Order";
import ImportOrders from "./ScreenOrders/ImportOrders/ImportOrders";
import {PlanShipments} from "./ScreenPlans/PlanShipments";
import TimeSheet from "./ScreenTimeSheet/TimeSheet/TimeSheet";
import {PlanDispatch} from "./ScreenPlans/PlanDispatch";
import {Accounts} from "./ScreenAccounts/Accounts";
import {Employees} from "./ScreenAccounts/Employees";
import {HandbookRouting} from "./modules/ScreenHandbook/HandbookRouting";
import {SettingsRouting} from "./ScreenSettings/SettingsRouting";
import {PlanPacking} from "./ScreenPlans/PlanPacking";
import SummaryTable from "./ScreenKpi/SummaryTable/SummaryTable";
import TimeTracking from "./ScreenTimeSheet/TimeTracking/TimeTracking";
import MyTimeTracking from "./ScreenEmployee/MyTimeTracking/MyTimeTracking";
import MyOrderMarks from "./ScreenEmployee/MyOrderMarks/MyOrderMarks";
import MySummaryTable from "./ScreenEmployee/MySummaryTable/MySummaryTable";
import {NomenclaturesRouting} from "./ScreenNomenclatures/NomenclaturesRouting";
import {Nomenclatures} from "./ScreenNomenclatures/Nomenclatures";
import {Nomenclature} from "./ScreenNomenclatures/NomenclaturePage";
import {LeadCharts} from "./ScreenLeads/LeadCharts";
import {LeadStatistics} from "./ScreenLeads/LeadStatistics";
import {Prices} from "./ScreenPrices/Prices";
import {SiteOptOrders} from "./ScreenSite/SiteOptOrders";
import {SiteCallbacks} from "./ScreenSite/SiteCallbacks";
import {TicketsRouting} from "./modules/ScreenTickets/TicketsRouting";
import {SiteShopOrders} from "./ScreenSite/SiteShopOrders";
import {SiteFeedback} from "./ScreenSite/SiteFeedback";
import {SiteSubscribe} from "./ScreenSite/SiteSubscribe";
import {SiteNews} from "./ScreenSite/SiteNews";
import {SiteBlogRouting} from "./ScreenSite/SiteBlog/SiteBlogRouting";
import {DashboardPacking} from "./ScreenDashboards/DashboardPacking";
import {DashboardRegistration} from "./ScreenDashboards/DashboardRegistration";
import {DashboardSticker} from "./ScreenDashboards/DashboardSticker";
import {DashboardAssembly} from "./ScreenDashboards/DashboardAssembly";
import {DashboardLogistics} from "./ScreenDashboards/DashboardLogistics";
import {DashboardPickup} from "./ScreenDashboards/DashboardPickup";
import {KanbanRouting} from "./modules/ScreenKanban/KanbanRouting";
import PlanWarehouseNew from "./ScreenPlans/PlanWarehouseNew/PlanWarehouse";
import {ProductWarehouse} from "./ScreenProductWarehouse/ProductWarehouse";
import {DashboardPrepare} from "./ScreenDashboards/DashboardPrepare";

export const Routing = () => {
  const {profile} = useProfile();

  return (
    !!profile && (
      <Routes>
        {isProfileRoleTypeTags(profile, [ROLE_ADMINISTRATOR]) && (
          <Route
            path="/accounts"
            element={<Accounts profile={profile}/>}
          />
        )}

        {isProfileRoleTypeTags(profile, [ROLE_ADMINISTRATOR, ROLE_DIRECTOR]) && (
          <Route path={"/settings/*"} element={<SettingsRouting/>}/>
        )}

        {(isProfileRoleTypeTags(profile, [ROLE_ADMINISTRATOR, ROLE_DIRECTOR]) ||
          isProfileOpportunityTypeTags(profile, [OPPORTUNITY_EMPLOYEES])) && (
          <Route path="/employees" element={<Employees/>}/>
        )}

        {(isProfileOpportunityTypeTags(profile, [
          OPPORTUNITY_ORDER_ALL,
        ])) && (
          <>
            <Route path="/buyer-orders" element={<BuyerOrders/>}/>
            <Route path={"/order/:orderUuid"} element={<Order/>}/>
          </>
        )}

        {(isProfileOpportunityTypeTags(profile, [
          OPPORTUNITY_ORDER_STATUS,
        ])) && (
          <>
            <Route path="/orders/view" element={<ViewOrders/>}/>
            <Route
              path={"/orders/state/:orderStateUuid"}
              element={<StateOrders/>}
            />
            <Route path={"/order/:orderUuid"} element={<Order/>}/>
          </>
        )}

        {(isProfileOpportunityTypeTags(profile, [
          OPPORTUNITY_PRODUCT_WAREHOUSE,
        ])) && (
          <>
            <Route path="/product-warehouse" element={<ProductWarehouse isEdit={false}/>}/>
          </>
        )}

        {(isProfileOpportunityTypeTags(profile, [
          OPPORTUNITY_NOMENCLATURES_ALL,
        ])) && (
          <>
            <Route path="/all-nomenclatures" element={<Nomenclatures isEdit={false}/>}/>
          </>
        )}

        {(isProfileOpportunityTypeTags(profile, [
          OPPORTUNITY_MY,
        ])) && (
          <>
            <Route path="/my/time-tracking" element={<MyTimeTracking/>}/>
            <Route path="/my/order-marks" element={<MyOrderMarks/>}/>
            <Route path="/my/summary-table" element={<MySummaryTable/>}/>
          </>
        )}

        {(isProfileRoleTypeTags(profile, [
          ROLE_ADMINISTRATOR,
          ROLE_DIRECTOR,
        ]) || isProfileOpportunityTypeTags(profile, [
          OPPORTUNITY_ORDER,
        ])) && (
          <>
            <Route path="/buyer-orders" element={<BuyerOrders/>}/>
            <Route path="/orders/view" element={<ViewOrders/>}/>
            <Route
              path={"/orders/state/:orderStateUuid"}
              element={<StateOrders/>}
            />
            <Route path={"/order/:orderUuid"} element={<Order/>}/>
            <Route path={"/import-orders"} element={<ImportOrders/>}/>
          </>
        )}

        {isProfileOpportunityTypeTags(profile, [
          OPPORTUNITY_PLAN_DISPATCH,
        ]) && (
          <Route path="/plan-dispatch" element={<PlanDispatch/>}/>
        )}

        {(isProfileRoleTypeTags(profile, [
          ROLE_ADMINISTRATOR,
          ROLE_DIRECTOR,
        ]) || isProfileOpportunityTypeTags(profile, [
          OPPORTUNITY_PLAN,
        ])) && (
          <>
            <Route path="/plan-kneading" element={<PlanKneading/>}/>
            <Route path="/plan-providing" element={<PlanProviding/>}/>
            <Route path="/plan-shipments" element={<PlanShipments/>}/>
            <Route path="/plan-dispatch" element={<PlanDispatch/>}/>
            <Route path="/plan-packing" element={<PlanPacking/>}/>
            {/*<Route path="/plan-warehouse" element={<PlanWarehouse/>}/>*/}

            <Route path="/plan-warehouse-new" element={<PlanWarehouseNew/>}/>
          </>
        )}

        {(isProfileRoleTypeTags(profile, [
          ROLE_ADMINISTRATOR,
          ROLE_DIRECTOR,
        ]) || isProfileOpportunityTypeTags(profile, [
          OPPORTUNITY_NOMENCLATURES,
        ])) && (
          <>
            <Route path="/product-warehouse" element={<ProductWarehouse isEdit={false}/>}/>
            <Route path="/all-nomenclatures" element={<Nomenclatures isEdit={false}/>}/>
            <Route path="/nomenclatures/*" element={<NomenclaturesRouting/>}/>
            <Route path="/nomenclature/:nomenclatureUuid/*" element={<Nomenclature/>}/>
          </>
        )}

        {(isProfileRoleTypeTags(profile, [
          ROLE_ADMINISTRATOR,
          ROLE_DIRECTOR,
        ]) || isProfileOpportunityTypeTags(profile, [
          OPPORTUNITY_DASHBOARD,
        ])) && (
          <>
            <Route path="/dashboard-registration" element={<DashboardRegistration/>}/>
            <Route path="/dashboard-sticker" element={<DashboardSticker/>}/>
            <Route path="/dashboard-packing" element={<DashboardPacking/>}/>
            <Route path="/dashboard-assembly" element={<DashboardAssembly/>}/>
            <Route path="/dashboard-logistics" element={<DashboardLogistics/>}/>
            <Route path="/dashboard-pickup" element={<DashboardPickup/>}/>
            <Route path="/dashboard-prepare" element={<DashboardPrepare/>}/>
          </>
        )}

        {(isProfileRoleTypeTags(profile, [
          ROLE_ADMINISTRATOR,
          ROLE_DIRECTOR,
        ]) || isProfileOpportunityTypeTags(profile, [
          OPPORTUNITY_SUMMARY,
        ])) && (
          <>
            <Route path="/order-marks" element={<OrderMarks/>}/>
            <Route path="/summary-table" element={<SummaryTable/>}/>
          </>
        )}

        {(isProfileRoleTypeTags(profile, [
          ROLE_ADMINISTRATOR,
          ROLE_DIRECTOR,
        ]) || isProfileOpportunityTypeTags(profile, [
          OPPORTUNITY_LEAD,
        ])) && (
          <>
            <Route path="/lead-charts" element={<LeadCharts/>}/>
            <Route path="/lead-statistics" element={<LeadStatistics/>}/>
          </>
        )}

        {(isProfileRoleTypeTags(profile, [
          ROLE_ADMINISTRATOR,
          ROLE_DIRECTOR,
        ]) || isProfileOpportunityTypeTags(profile, [
          OPPORTUNITY_SITE,
        ])) && (
          <>
            <Route path="/site-callbacks" element={<SiteCallbacks/>}/>
            <Route path="/site-feedback" element={<SiteFeedback/>}/>
            <Route path="/site-subscribe" element={<SiteSubscribe/>}/>

            <Route path="/site-opt-orders" element={<SiteOptOrders/>}/>
            <Route path="/site-shop-orders" element={<SiteShopOrders/>}/>

            <Route path="/site-news" element={<SiteNews/>}/>
            <Route path="/site-blog/*" element={<SiteBlogRouting/>}/>
          </>
        )}

        {(isProfileRoleTypeTags(profile, [
          ROLE_ADMINISTRATOR,
          ROLE_DIRECTOR,
        ]) || isProfileOpportunityTypeTags(profile, [
          OPPORTUNITY_PRICES,
        ])) && (
          <>
            <Route path="/prices" element={<Prices/>}/>
          </>
        )}

        {(isProfileRoleTypeTags(profile, [
          ROLE_ADMINISTRATOR,
          ROLE_DIRECTOR,
        ]) || isProfileOpportunityTypeTags(profile, [
          OPPORTUNITY_TIME_SHEET,
          OPPORTUNITY_TIME_TRACKING,
        ])) && (
          <>
            {(isProfileRoleTypeTags(profile, [
              ROLE_ADMINISTRATOR,
              ROLE_DIRECTOR,
            ]) || isProfileOpportunityTypeTags(profile, [
              OPPORTUNITY_TIME_SHEET,
            ])) && (
              <Route path="/time-sheet" element={<TimeSheet/>}/>
            )}
            {(isProfileRoleTypeTags(profile, [
              ROLE_ADMINISTRATOR,
              ROLE_DIRECTOR,
            ]) || isProfileOpportunityTypeTags(profile, [
              OPPORTUNITY_TIME_TRACKING,
            ])) && (
              <Route path="/time-tracking" element={<TimeTracking/>}/>
            )}
          </>
        )}

        {(isProfileRoleTypeTags(profile, [
          ROLE_ADMINISTRATOR,
          ROLE_DIRECTOR,
        ]) || isProfileOpportunityTypeTags(profile, [
          OPPORTUNITY_KANBAN,
        ])) && (
          <>
            <Route path="/kanban/*" element={<KanbanRouting/>}/>
          </>
        )}

        {(isProfileRoleTypeTags(profile, [
          ROLE_ADMINISTRATOR,
          ROLE_DIRECTOR]) || isProfileOpportunityTypeTags(profile, [
          OPPORTUNITY_TICKETS,
        ])) && (
          <>
            <Route path="/tickets/*" element={<TicketsRouting/>}/>
          </>
        )}

        {(isProfileRoleTypeTags(profile, [
          ROLE_ADMINISTRATOR,
          ROLE_DIRECTOR]) || isProfileOpportunityTypeTags(profile, [
          OPPORTUNITY_HANDBOOK,
        ])) && (
          <>
            <Route path="/handbook/*" element={<HandbookRouting/>}/>
          </>
        )}

        {isProfileRoleTypeTags(profile, [ROLE_DIRECTOR]) && (
          <Route exact path="/" element={<Navigate to="/employees" replace/>}/>
        )}

        {isProfileRoleTypeTags(profile, [ROLE_ADMINISTRATOR]) && (
          <Route exact path="/" element={<Navigate to="/accounts" replace/>}/>
        )}

        <Route path="/login" element={<Navigate to="/"/>}/>
      </Routes>
    )
  );
};
