import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  Textarea,
  useList,
  Select,
  useNotification,
  Modal,
  jsonFormatText,
  isValidateNullText, Input,
} from "@vokymlak/kabinet-ui";
import ModalSpecificationItemSelect
  from "../NomenclaturePage/components/NomenclatureSpecifications/ModalSpecificationItemSelect";

function convertToSEOAddress(productName) {
  // Преобразование к нижнему регистру
  productName = productName.toLowerCase();

  // Замена пробелов на дефисы
  productName = productName.replace(/\s+/g, '-');

  // Замена русских символов на английские эквиваленты
  const cyrillicToLatinMap = {
    "а": 'a', "б": 'b', "в": 'v', "г": 'g', "д": 'd', "е": 'e', "ё": 'yo', "ж": 'zh',
    "з": 'z', "и": 'i', "й": 'y', "к": 'k', "л": 'l', "м": 'm', "н": 'n', "о": 'o',
    "п": 'p', "р": 'r', "с": 's', "т": 't', "у": 'u', "ф": 'f', "х": 'h', "ц": 'c',
    "ч": 'ch', "ш": 'sh', "щ": 'sch', "ъ": '%', "ы": 'y', "ь": '%', "э": 'e', "ю": 'yu',
    "я": 'ya'
  };
  productName = productName.replace(/[а-яё]/g, function (char) {
    return cyrillicToLatinMap[char] || char;
  });

  // Удаление специальных символов и знаков препинания, сохраняя русские символы
  productName = productName.replace(/[^\dA-Za-zА-Яа-я-]/g, '');

  // Удаление двойных и последних дефисов
  productName = productName.replace(/[-]{2,}/g, '-').replace(/^-+|-+$/g, '');

  // Возвращаем результат
  return productName;
}

function ModalNomenclatureAdd(props) {
  const inputName = useRef(null);
  const inputDescription = useRef(null);
  const inputStructure = useRef(null);
  const inputRecommend = useRef(null);

  const [selectItem, setSelectItem] = useState(null)
  const [isModalSelectItem, setIsModalSelectItem] = useState(false)

  const selectMeasurementUnitType = useRef(null);
  const inputValue = useRef(null);
  const inputPrice = useRef(null);
  const inputPriceOpt = useRef(null);
  const inputPriceSale = useRef(null);
  const inputPriceSaleOpt = useRef(null);
  const inputSeoUrl = useRef(null);

  const [isPcs, setIsPcs] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isBuyerChoice, setIsBuyerChoice] = useState(false);
  const [isSeparateFromProduct, setIsSeparateFromProduct] = useState(false);

  const inputArticle = useRef(null);

  const { notification } = useNotification();

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0 && numberString !== "null"
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const numberConvertV = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(3)))
      : null;
  };

  const changeAdd = (event) => {
    event.preventDefault();

    const name = jsonFormatText(inputName.current.value);
    const description = jsonFormatText(inputDescription.current.value);
    const structure = jsonFormatText(inputStructure.current.value);
    const recommend = jsonFormatText(inputRecommend.current.value);
    const seoUrl = jsonFormatText(inputSeoUrl.current.value);

    let measurementUnitType = selectMeasurementUnitType.current.value;
    measurementUnitType = measurementUnitType.length > 0
      ? measurementUnitType === "null"
        ? null
        : measurementUnitType
      : null
    const value = inputValue.current.value;
    const price = inputPrice.current.value;
    const priceOpt = inputPriceOpt.current.value;
    const priceSale = inputPriceSale.current.value;
    const priceSaleOpt = inputPriceSaleOpt.current.value;

    const article = jsonFormatText(inputArticle.current.value)

    if (isValidateNullText(name)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      name: name,
      description: description,
      structure: structure,
      recommend: recommend,
      measurement_unit_type: !!measurementUnitType ? {
        tag: measurementUnitType,
      } : null,
      value: numberConvertV(value),
      price: numberConvert(price),
      price_opt: numberConvert(priceOpt),
      price_sale: numberConvert(priceSale),
      price_sale_opt: numberConvert(priceSaleOpt),
      is_new: isNew,
      is_buyer_choice: isBuyerChoice,
      is_pcs: isPcs,
      product: !!selectItem ? {
        uuid: selectItem.uuid,
      } : null,
      seo_url: seoUrl,
      article: article,
      is_separate_from_product: !!selectItem ? isSeparateFromProduct : false,
    });

    axios
      .post("/api/nomenclature/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Номенклатура добавлена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const generateSeoUrl = () => {
    const name = jsonFormatText(inputName.current.value);

    if (!isValidateNullText(name)) {
      const seoUrl = convertToSEOAddress(name)
      console.log(seoUrl)
      inputSeoUrl.current.value = seoUrl
    }
  }

  return (
    <>
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">
          Новая
          <div className="Text__signature">Номенклатура</div>
        </div>

        <div className="Form__field">
          <Textarea reference={inputName} label="Название *"/>
        </div>

        <div className="Form__field">
          <Input reference={inputSeoUrl}
                 isFixed={true}
                 label="SEO адрес"/>
          <div className="Action__link Action__link_blue" style={{
            marginTop: 4,
            fontSize: 12,
            padding: "0 4px"
          }} onClick={() => generateSeoUrl()}>
            Сгенерировать по названию
          </div>
        </div>

        <div className="Form__field">
          <Input reference={inputArticle} label="Артикуль" />
        </div>

        <div className="Form__field">
          <Textarea reference={inputDescription} label="Описание"/>
        </div>

        <div className="Form__field">
          <Textarea reference={inputStructure} label="Состав"/>
        </div>

        <div className="Form__field">
          <Textarea reference={inputRecommend} label="Рекомендации"/>
        </div>

        <div className="Form__field">
          <Select
            isFixed={true}
            reference={selectMeasurementUnitType}
            options={[
              {
                value: "null",
                title: "Не выбрано",
              },
              {
                value: "MG",
                title: "Миллиграммы",
              },
              {
                value: "G",
                title: "Граммы",
              },
              {
                value: "KG",
                title: "Килограммы",
              },
              {
                value: "ML",
                title: "Миллилитры",
              },
              {
                value: "L",
                title: "Литры",
              },
              {
                value: "PCS",
                title: "Штуки",
              },
              {
                value: "MM",
                title: "Миллиметры",
              },
              {
                value: "M",
                title: "Метры",
              }]}
            label="Единица измерения"
          />
        </div>

        <div className="Form__field">
          <Input type={'number'} reference={inputValue} label="Размер"/>
        </div>

        <div className="Form__field">
          <Input type={'number'} reference={inputPrice} label="Цена"/>
        </div>

        <div className="Form__field">
          <Input type={'number'} reference={inputPriceSale} label="Цена по акции"/>
        </div>

        <div className="Form__field">
          <Input type={'number'} reference={inputPriceOpt} label="Цена оптовая"/>
        </div>

        <div className="Form__field">
          <Input type={'number'} reference={inputPriceSaleOpt} label="Цена оптовая по акции"/>
        </div>

        <div className="Form__field">
          <div
            className="Button__swith"
            style={{
              padding: "8px",
              width: "100%",
              border: isPcs ? "2px solid #57a1f5" : "2px dashed #20202015",
              backgroundColor: isPcs ? "#57a1f5" : "#ffffff",
              borderRadius: 6,
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => setIsPcs(!isPcs)}
          >
            <div
              className="Swith__text"
              style={{
                fontSize: 17,
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              Измерять в штучно
            </div>
            <div
              className={
                "Swith Swith_gray" +
                ((isPcs && " Swith_active Swith_active_gray") || "")
              }
            ></div>
          </div>
        </div>

        <div className="Form__field">
          <div
            className="Button__swith"
            style={{
              padding: "8px",
              width: "100%",
              border: isNew ? "2px solid #ffe082" : "2px dashed #20202015",
              backgroundColor: isNew ? "#ffe082" : "#ffffff",
              borderRadius: 6,
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => setIsNew(!isNew)}
          >
            <div
              className="Swith__text"
              style={{
                fontSize: 17,
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              Новинка
            </div>
            <div
              className={
                "Swith Swith_gray" +
                ((isNew && " Swith_active Swith_active_gray") || "")
              }
            ></div>
          </div>
        </div>

        <div className="Form__field">
          <div
            className="Button__swith"
            style={{
              padding: "8px",
              width: "100%",
              border: isBuyerChoice ? "2px solid #f58ea6" : "2px dashed #20202015",
              backgroundColor: isBuyerChoice ? "#f58ea6" : "#ffffff",
              borderRadius: 6,
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => setIsBuyerChoice(!isBuyerChoice)}
          >
            <div
              className="Swith__text"
              style={{
                fontSize: 17,
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              Хит
            </div>
            <div
              className={
                "Swith Swith_gray" +
                ((isBuyerChoice && " Swith_active Swith_active_gray") || "")
              }
            ></div>
          </div>
        </div>

        {!selectItem && (
          <div className="Form__field"
               style={{
                 cursor: "pointer"
               }}
               onClick={() => setIsModalSelectItem(true)}>
            <div className="Field__label">
              К товару
            </div>
            <div className="Field__text">
              -
            </div>
          </div>
        )}

        {!!selectItem && (
          <div className="Form__field"
               style={{
                 cursor: "pointer"
               }}
               onClick={() => setIsModalSelectItem(true)}>
            <div className="Field__label">
              К товару
            </div>
            <div className="Field__text">
              №{selectItem.id} - {!!selectItem.name && selectItem.name}
              {(!!selectItem.code || !!selectItem.article) && (
                <div
                  style={{
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                >
                  {!!selectItem.code && selectItem.code}
                  {!!selectItem.code && !!selectItem.article && " | "}
                  {!!selectItem.article && selectItem.article}
                </div>
              )}
            </div>
          </div>
        )}

        {!!selectItem && (
          <div className="Form__field Form__field_last">
            <div
              className="Button__swith"
              style={{
                padding: "8px",
                width: "100%",
                border: isSeparateFromProduct ? "2px solid #57a1f5" : "2px dashed #20202015",
                backgroundColor: isSeparateFromProduct ? "#57a1f5" : "#ffffff",
                borderRadius: 6,
                marginTop: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
              onClick={() => setIsSeparateFromProduct(!isSeparateFromProduct)}
            >
              <div
                className="Swith__text"
                style={{
                  fontSize: 17,
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Отображать отдельно от товара
              </div>
              <div
                className={
                  "Swith Swith_gray" +
                  ((isSeparateFromProduct && " Swith_active Swith_active_gray") || "")
                }
              ></div>
            </div>
          </div>
        )}

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Добавить
          </button>
        </div>
      </form>
    </Modal>

      {isModalSelectItem && (
        <ModalSpecificationItemSelect
          item={selectItem}
          successfulRequest={item =>
            setSelectItem(item)
          }
          handleClose={() => setIsModalSelectItem(false)}
    />
  )}
</>
  );
}

export default ModalNomenclatureAdd;
